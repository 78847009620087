<template lang="pug">
  v-app
    .top-bar
      router-link.brand-logo(to="/")
        img(
          src="@/assets/images/icon/logo.png"
        )
      router-link.back-logo(to="/")
        img(src="@/assets/images/quiz/back.png")
    router-view#main-page
</template>

<script>
export default {
  mounted() {
    // console.log(this.$store.state.customer);
    // console.log(this.$store.getters.isQuiz1Done);
    this.$i18n.locale = this.$store.state.customer.locale;

    if (this.$store.getters.isQuiz3Done) this.$router.push({ name: "QuizDone" });
    else if (this.$store.getters.isQuiz2Done)
      this.$router.push({ name: "Quiz3" });
    else if (this.$store.getters.isQuiz1Done)
      this.$router.push({ name: "Quiz2" });
    else this.$router.push({ name: "QuizMain" });
  }
};
</script>

<style lang="sass" scoped>
.v-application
  max-height: 100vh !important
  overflow: hidden !important

.top-bar
  display: flex
  flex-direction: row
  justify-content: space-between
  height: 10vh

.brand-logo
  margin: auto 2rem
  z-index: 99
  padding: 1rem
  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 30%

.back-logo
  margin: auto 2rem
  padding: 1rem
  z-index: 99

  @media #{map-get($display-breakpoints, 'sm-and-down')}
    width: 10%
    padding: 0
</style>
